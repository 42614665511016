export default [
  { id: 1, text: 'ancient' },
  { id: 2, text: 'unveil' },
  { id: 3, text: 'gun' },
  { id: 4, text: 'ancient' },
  { id: 5, text: 'unveil' },
  { id: 6, text: 'gun' },
  { id: 7, text: 'ancient' },
  { id: 8, text: 'unveil' },
  { id: 9, text: 'gun' },
  { id: 10, text: 'ancient' },
  { id: 11, text: 'unveil' },
  { id: 12, text: 'gun' },
  { id: 13, text: 'ancient' },
  { id: 14, text: 'unveil' },
  { id: 15, text: 'gun' },
  { id: 16, text: 'ancient' },
  { id: 17, text: 'unveil' },
  { id: 18, text: 'gun' },
  { id: 19, text: 'ancient' },
  { id: 20, text: 'unveil' },
  { id: 21, text: 'gun' },
  { id: 22, text: 'ancient' },
  { id: 23, text: 'unveil' },
  { id: 24, text: 'gun' },
];
